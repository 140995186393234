import React from 'react';
import sharonlogo from './Sharon Speech Services 1-01.jpg'
import './App.css';
import Greet from './Components/greet';
import "./styles.css";

class App extends React.Component {

  render(){
    return (
        <div className='App'>
            <div className="container" id="brand">
                <img src={sharonlogo} className="logo" alt="Logo" />
            </div>
          <Greet />
        </div>
    );
  }
}

export default App;
