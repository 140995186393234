import React from 'react';
import "./logostyle.css";

class Greet extends React.Component {


    render(){
        window.location.href = 'https://muse.io/sharonspeechservices';
        return(
            <div>
                <h1>While we tidy up I can be found on the following social platforms!!</h1>
                <ul>
                    <li><a href={'https://m.facebook.com/SharonSpeechServices'}>Facebook</a></li>
                    <li><a href={'https://instagram.com/sharonspeechservices?utm_medium=copy_link'}>Instagram</a></li>
                </ul>
            </div>
        );
    }
}
export default Greet;